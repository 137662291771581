import { db } from "../Firebase/Fire";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  where,
  and,
  orderBy,
  disableNetwork,
  enableNetwork,
  Firestore,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase/Fire";

const GetFormData = (loaded, content) => {
  const vmcRef = doc(db, "form", "data");
  getDoc(vmcRef)
    .then((res) => {
      if (!res.empty) {
        content(res.data());
        loaded(true);
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {});
};
const GetScrapperURL = (content) => {
  const scrapperRef = doc(db, "settings", "scrapper");
  getDoc(scrapperRef)
    .then((res) => {
      if (!res.empty) {
        content(res.data());
        // console.log(res.data());
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {});
};
const GetFDSMeeting = (content) => {
  const dayRef = doc(db, "settings", "fds");
  getDoc(dayRef)
    .then((res) => {
      if (!res.empty) {
        content(res.data().domingo);
        // console.log(res.data().domingo);
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};

const GetPrediData = (loaded, content) => {
  const ref = collection(db, "predicacion");
  const result = [];
  getDocs(ref)
    .then((res) => {
      if (!res.empty) {
        res.docs.forEach((doc) => {
          result.push({ ...doc.data(), key: doc.id });
        });
        content(result);
        loaded(true);
        // console.log(result);
      } else {
        content([]);
        loaded(true);
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {});
};

const GetPrediDataFiltered = async (loaded, content) => {
  if (navigator.onLine) {
    // console.log("Buscando");
    const ref = collection(db, "predicacion");
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    // date.setHours(date.getHours() - 4);
    const q = query(ref, where("date", ">=", date.getTime()), orderBy("date"));
    const result = [];
    try {
      let res = await getDocs(q);
      if (!res.empty) {
        res.docs.forEach((doc) => {
          result.push({ ...doc.data(), key: doc.id });
        });
        content(result);
        localStorage.setItem("predi", JSON.stringify(result));
        loaded(true);
        // console.log(result);
      } else {
        content([]);
        localStorage.setItem("predi", "[]");
        loaded(true);
      }
    } catch (error) {
      console.log("error");
    }
  } else {
    let data = localStorage.getItem("predi");
    if (data) {
      content(JSON.parse(data));
      loaded(true);
    }
  }
};
const GetCarritoData = (loaded, content) => {
  const ref = collection(db, "carrito");
  const result = [];
  getDocs(ref)
    .then((res) => {
      if (!res.empty) {
        res.docs.forEach((doc) => {
          result.push({ ...doc.data(), key: doc.id });
        });
        content(result);
        loaded(true);
      } else {
        content([]);
        loaded(true);
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {});
};
const GetCarritoFiltered = (loaded, content) => {
  if (navigator.onLine) {
    const ref = collection(db, "carrito");
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const q = query(ref, where("date", ">=", date.getTime()), orderBy("date"));
    const result = [];
    getDocs(q)
      .then((res) => {
        if (!res.empty) {
          res.docs.forEach((doc) => {
            result.push({ ...doc.data(), key: doc.id });
          });
          const tmp = GroupByDay(result);
          const week = [];
          for (const key in tmp) {
            if (Object.hasOwnProperty.call(tmp, key)) {
              week.push(tmp[key]);
            }
          }
          const order = week.sort((a, b) =>
            a[0].date > b[0].date ? 1 : b[0].date > a[0].date ? -1 : 0
          );

          content(order);
          localStorage.setItem("carrito", JSON.stringify(order));
          // console.log(order);
          loaded(true);
        } else {
          content([]);
          localStorage.setItem("carrito", "[]");
          loaded(true);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  } else {
    let data = localStorage.getItem("carrito");
    if (data) {
      content(JSON.parse(data));
      loaded(true);
    }
  }
};
function GroupByDay(list) {
  const groups = list.reduce(
    (groups, item) => ({
      ...groups,
      [new Date(item.date).getDate()]: [
        ...(groups[new Date(item.date).getDate()] || []),
        item,
      ],
    }),
    {}
  );
  // console.log(groups);
  return groups;
}
const EditPrediItem = (item, onEnd) => {
  const { key, ...fixed } = item;
  // console.log(fixed);
  const ref = doc(db, "predicacion", item.key);
  updateDoc(ref, fixed)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};
const AddCarritoItem = (item, onEnd) => {
  const ref = collection(db, "carrito");
  addDoc(ref, item)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};
const EditCarritoItem = (item, onEnd) => {
  const { key, ...fixed } = item;
  // console.log(fixed);
  const ref = doc(db, "carrito", item.key);
  updateDoc(ref, fixed)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};
const AddPrediItem = (item, onEnd) => {
  const ref = collection(db, "predicacion");
  addDoc(ref, item)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};
const DeletePrediItem = (item, onEnd) => {
  const ref = doc(db, "predicacion", item.key);
  deleteDoc(ref)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};
const DeleteCarritoItem = (item, onEnd) => {
  const ref = doc(db, "carrito", item.key);
  deleteDoc(ref)
    .then((e) => {
      // console.log(e);
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onEnd();
    });
};

const GetVMC = (loaded, content) => {
  // console.log("Data")
  const ref = collection(db, "vmc");
  const result = [];
  getDocs(ref)
    .then((res) => {
      if (!res.empty) {
        res.docs.forEach((doc) => {
          result.push({ ...doc.data(), key: doc.id });
        });
        content(result);
        // console.log(result);
      } else {
        content([]);
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      loaded(true);
    });
};
const GetVMCFiltered = (loaded, content, date) => {
  if (navigator.onLine) {
    const ref = collection(db, "vmc");
    date.setDate(
      date.getDate() - (date.getDay() === 0 ? 7 : date.getDay()) + 1
    );
    date.setHours(0, 0, 0, 0);
    // console.log(date);
    const q = query(ref, where("date", ">=", date.getTime()), orderBy("date"));
    const result = [];
    getDocs(q)
      .then((res) => {
        if (!res.empty) {
          res.docs.forEach((doc) => {
            result.push({ ...doc.data(), key: doc.id });
          });
          content(result);
          localStorage.setItem("vmc", JSON.stringify(result));
          // console.log(result);
        } else {
          content([]);
          localStorage.setItem("vmc", "[]");
        }
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        loaded(true);
      });
  } else {
    let data = localStorage.getItem("vmc");
    if (data) {
      content(JSON.parse(data));
      loaded(true);
    }
  }
};
const GetDatos = (loaded, content) => {
  const ref = collection(db, "datos");
  const result = [];
  getDocs(ref)
    .then((res) => {
      if (!res.empty) {
        res.docs.forEach((doc) => {
          result.push({ ...doc.data(), key: doc.id });
        });
        content(result);
        // console.log(result);
        loaded(true);
      } else {
        content([]);
        loaded(true);
      }
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {});
};
const GetDatosByCity = (loaded, content, city) => {
  // console.log(city);
  if (navigator.onLine) {
    const ref = collection(db, "datos");
    const q = query(ref, where("city", "==", city), orderBy("name"));
    const result = [];
    getDocs(q)
      .then((res) => {
        if (!res.empty) {
          res.docs.forEach((doc) => {
            result.push({ ...doc.data(), key: doc.id });
          });
          content(result);
          localStorage.setItem("datos" + city, JSON.stringify(result));
          // console.log(result);
        } else {
          content([]);
          localStorage.setItem("datos" + city, "[]");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        loaded(true);
      });
  } else {
    let data = localStorage.getItem("datos" + city);
    if (data) {
      content(JSON.parse(data));
      loaded(true);
    }
  }
};
const GetDatosByCityFiltered = (loaded, content, city) => {
  // console.log(city);
  if (navigator.onLine) {
    const ref = collection(db, "datos");
    const q = query(ref, where("city", "==", city), orderBy("name"));
    const result = [];
    getDocs(q)
      .then((res) => {
        if (!res.empty) {
          res.docs.forEach((doc) => {
            result.push({ ...doc.data(), key: doc.id });
          });
          const final = result.filter((item) => item?.nopasar == false);
          content(final);
          localStorage.setItem("datos" + city, JSON.stringify(final));
          // console.log(result);
        } else {
          content([]);
          localStorage.setItem("datos" + city, "[]");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        loaded(true);
      });
  } else {
    let data = localStorage.getItem("datos" + city);
    if (data) {
      content(JSON.parse(data));
      loaded(true);
    }
  }
};
const SaveDatoWithKey = (key, data, onComplete) => {
  const ref = doc(db, "datos", key);
  updateDoc(ref, { ...data, key: "" })
    .then((res) => {})
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};
const DeleteDatoWithKey = (key, onComplete) => {
  const ref = doc(db, "datos", key);
  deleteDoc(ref)
    .then((res) => {})
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};

const SaveNewDato = (data, onComplete) => {
  const ref = collection(db, "datos");
  addDoc(ref, data)
    .then((res) => {})
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};
const SaveVMCWithKey = (key, data, onComplete) => {
  const ref = doc(db, "vmc", key);
  // console.log(key);
  // console.log(data);
  updateDoc(ref, { ...data, key: "" })
    .then((res) => {})
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};
const SaveNewVMC = (data, onComplete) => {
  const ref = collection(db, "vmc");
  // console.log(data);
  addDoc(ref, data)
    .then((res) => {})
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};
const DeleteVMCWithKey = (key, data, onComplete) => {
  const ref = doc(db, "vmc", key);
  deleteDoc(ref)
    .then((res) => {})
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      onComplete();
    });
};

export {
  GetFormData,
  GetPrediData,
  EditPrediItem,
  AddPrediItem,
  DeletePrediItem,
  GetVMC,
  SaveVMCWithKey,
  SaveNewVMC,
  AddCarritoItem,
  EditCarritoItem,
  GetCarritoData,
  DeleteCarritoItem,
  GetCarritoFiltered,
  GetVMCFiltered,
  DeleteVMCWithKey,
  GetPrediDataFiltered,
  SaveNewDato,
  SaveDatoWithKey,
  GetDatosByCity,
  GetDatos,
  DeleteDatoWithKey,
  GetScrapperURL,
  GetDatosByCityFiltered,
  GetFDSMeeting,
};

import React, { useEffect, useState } from "react";
import css from "../FDS/FDS.module.css";
import Privilegio from "../Privilegio/Privilegio";

const FDS = ({ data, date, esDomingo }) => {
  const [diaReunion, setDiaReunion] = useState(new Date());
  useEffect(() => {
    if (data) {
      CalculateWeek();
    }
  }, [data, esDomingo]);

  function CalculateWeek() {
    let primerDia = new Date(date); // Primer día de la semana
    let fdsDay = new Date(primerDia);
    if (esDomingo) {
      fdsDay.setDate(primerDia.getDate() + 6); // Sumar 2 días para llegar al sabado
    } else {
      fdsDay.setDate(primerDia.getDate() + 5); // Sumar 2 días para llegar al sabado
    }
    setDiaReunion(fdsDay);
  }
  function RenderBody() {
    if (data?.especial) {
      return (
        <div className={css.especial}>
          <img
            className={css.img}
            src={data?.discurso?.img ? data?.discurso?.img : ""}
          />
          <p className={css.textS}>{data?.especial}</p>
        </div>
      );
    } else {
      return (
        <div className={css.main}>
          <div className={css.header}>
            <p className={css.title}>
              {`${diaReunion.getDate()} ${diaReunion.toLocaleString("es-CL", {
                month: "long",
              })}`}
            </p>
            <p className={css.title}>{`Grupo ${data?.aseo}`}</p>
          </div>
          <div className={css.rows}>
            <Privilegio
              data={data?.presidencia}
              img={"presidencia"}
              text={"Presidencia"}
            />
            <Privilegio
              data={data?.acomodacion}
              img={"acomodacion"}
              text={"Acomodación"}
            />
            <Privilegio
              data={data?.proyeccion}
              img={"proyeccion"}
              text={"Proyección"}
            />
            <Privilegio data={data?.apoyo} img={"apoyo"} text={"Apoyo"} />
            <Privilegio data={data?.camara} img={"camara"} text={"Cámara"} />
            <Privilegio
              data={data?.traslado}
              img={"transporte"}
              text={"Traslado"}
            />
          </div>
          <div className={css.rows}>
            <p
              className={css.text}
              style={{ display: data?.discurso?.tema ? "" : "none" }}
            >
              Tema: {data?.discurso?.tema}
            </p>
            <div style={{ display: data?.discurso?.img ? "" : "none" }}>
              <Privilegio data={data?.discurso} text={"Discursante"} />
            </div>
          </div>
        </div>
      );
    }
  }

  // if (new Date().getTime() >= hasta.getTime()) return;
  if (data?.status) {
    return RenderBody();
  }
};

export default FDS;
